export default {
    name: "ListTSavingBlock",
    data() {
        return {
            property: {
                listElement: {
                    savingBlock: {
                        rows: 0,
                        perPage: 10,
                        currentPage: 1,
                        downloading: false,
                        message: "",
                    },
                },
                filterDto: {
                    accountNumber: "",
                    cifIdName: "",
                },
            },
            table: {
                data: {
                    savingBlock: [],
                },
            },
        };
    },
    methods: {
        async deleteSavingBlock(props) {
            if (window.navigator.onLine === false) {
                this.$buefy.toast.open(
                    this.$NotificationUtils.connectionsError
                );
                return;
            } else {
                this.$buefy.dialog.confirm({
                    message: "Hapus Data Ini ?",
                    type: "is-success",
                    onConfirm: async () => {
                        try {
                            const resp = await this.$store.dispatch({
                                type: "DELETE_DATA",
                                endPoint: "transaction",
                                reqUrl:
                                    "saving-block/" + props.row.accountNumber,
                            });
                            if (resp.data.code === "SUCCESS") {
                                this.$buefy.toast.open(
                                    this.$NotificationUtils.success
                                );
                                setTimeout(() => {
                                    this.getLoanChartOfAccount();
                                }, 1000);
                            } else {
                                this.$buefy.dialog.alert({
                                    title: "Gagal !",
                                    message: resp.data.errorFields
                                        ? this.globalNotificationErrorFieldToText(
                                              resp.data.errorFields
                                          )
                                        : resp.data.message,
                                    confirmText: "Ok",
                                    type: "is-danger",
                                });
                            }
                        } catch (error) {
                            this.$buefy.dialog.alert({
                                title: "Gagal !",
                                message: error.response
                                    ? error.response.data.errorFields
                                        ? this.globalNotificationErrorFieldToText(
                                              error.response.data.errorFields
                                          )
                                        : error.response.statusText
                                    : "Terjadi Kesalahan",
                                confirmText: "Ok",
                                type: "is-danger",
                            });
                        }
                    },
                });
            }
        },
        async searchByAccountNumber(event) {
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.downloading = true;
            const timeout = 500;

            try {
                const resp = await this.$store.dispatch({
                    type: "GET_LIST_DATA",
                    endPoint: "transaction",
                    reqUrl: "saving-block/findAll-saving-blocked",
                    payload: {
                        accountNumber: event,
                        cifIdName: this.property.filterDto.cifIdName,
                        page: 0,
                        size: this.property.listElement.savingBlock.perPage,
                    },
                });
                setTimeout(() => {
                    if (resp.data.code === "SUCCESS") {
                        this.table.data.savingBlock = resp.data.data.content;
                        this.property.listElement.savingBlock.rows =
                            resp.data.data.totalElements;
                        this.property.listElement.savingBlock.currentPage =
                            resp.data.data.pageable.pageNumber + 1;
                    } else {
                        this.failedGetDataSavingBlock(resp);
                    }
                }, timeout);
            } catch (error) {
                setTimeout(() => {
                    this.handleErrorSavingBlock(error);
                }, timeout);
            } finally {
                setTimeout(() => {
                    this.property.listElement.savingBlock.downloading = false;
                }, timeout);
            }
        },
        async searchByCifIdName(event) {
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.downloading = true;
            const timeout = 500;
            try {
                const resp = await this.$store.dispatch({
                    type: "GET_LIST_DATA",
                    endPoint: "transaction",
                    reqUrl: "saving-block/findAll-saving-blocked",
                    payload: {
                        accountNumber: this.property.filterDto.accountNumber,
                        cifIdName: event,
                        page: 0,
                        size: this.property.listElement.savingBlock.perPage,
                    },
                });
                setTimeout(() => {
                    if (resp.data.code === "SUCCESS") {
                        this.table.data.savingBlock = resp.data.data.content;
                        this.property.listElement.savingBlock.rows =
                            resp.data.data.totalElements;
                        this.property.listElement.savingBlock.currentPage =
                            resp.data.data.pageable.pageNumber + 1;
                    } else {
                        this.failedGetDataSavingBlock(resp);
                    }
                }, timeout);
            } catch (error) {
                setTimeout(() => {
                    this.handleErrorSavingBlock(error);
                }, timeout);
            } finally {
                setTimeout(() => {
                    this.property.listElement.savingBlock.downloading = false;
                }, timeout);
            }
        },

        routeToPageProcessBlocked(props) {
            sessionStorage.setItem(
                "ACCOUNT_NUMBER_SAVING_ACCOUNT",
                props.row.accountNumber
            );
            this.$router.push("process");
        },
        handleErrorSavingBlock(error) {
            console.log(error.response);
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.rows = 0;
            this.property.listElement.savingBlock.currentPage = 1;
            this.property.listElement.savingBlock.message = error.response
                ? `Gagal Mengunduh Data : ${error.response.statusText}`
                : "Terjadi Kesalahan";
        },
        async changePaginationSavingBlock(event) {
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.downloading = true;
            const timeout = 500;
            try {
                const resp = await this.$store.dispatch({
                    type: "GET_LIST_DATA",
                    endPoint: "transaction",
                    reqUrl: "saving-block/findAll-saving-blocked",
                    payload: {
                        accountNumber: this.property.filterDto.accountNumber,
                        cifIdName: this.property.filterDto.cifIdName,
                        page: event - 1,
                        size: this.property.listElement.savingBlock.perPage,
                    },
                });
                setTimeout(() => {
                    if (resp.data.code === "SUCCESS") {
                        this.table.data.savingBlock = resp.data.data.content;
                        this.property.listElement.savingBlock.rows =
                            resp.data.data.totalElements;
                    } else {
                        this.failedGetDataSavingBlock(resp);
                    }
                }, timeout);
            } catch (error) {
                setTimeout(() => {
                    this.handleErrorSavingBlock(error);
                }, timeout);
            } finally {
                setTimeout(() => {
                    this.property.listElement.savingBlock.downloading = false;
                }, timeout);
            }
        },
        async getSavingBlock() {
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.downloading = true;
            const timeout = 500;

            try {
                const resp = await this.$store.dispatch({
                    type: "GET_LIST_DATA",
                    endPoint: "transaction",
                    reqUrl: "saving-block/findAll-saving-blocked",
                    payload: {
                        accountNumber: "",
                        cifIdName: "",
                        page: 0,
                        size: 10,
                        dateTo: "",
                        dateFrom: "",
                    },
                });
                setTimeout(() => {
                    if (resp.data.code === "SUCCESS") {
                        this.table.data.savingBlock = resp.data.data.content;
                        this.property.listElement.savingBlock.rows =
                            resp.data.data.totalElements;
                    } else {
                        this.failedGetDataSavingBlock(resp);
                    }
                }, timeout);
            } catch (error) {
                setTimeout(() => {
                    this.handleErrorSavingBlock(error);
                }, timeout);
            } finally {
                setTimeout(() => {
                    this.property.listElement.savingBlock.downloading = false;
                }, timeout);
            }
        },
        failedGetDataSavingBlock(resp) {
            this.table.data.savingBlock = [];
            this.property.listElement.savingBlock.rows = 0;
            this.property.listElement.savingBlock.message = resp.data.message;
        },
    },
    mounted() {
        this.getSavingBlock();
    },
};
